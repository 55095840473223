<template>
  <div
    class="form-login d-flex justify-content-center align-items-center flex-column formLogin adminLogin"
  >
    <div class="logo img-logo-header" v-if="dataShop">
      <img
        v-if="logoShop"
        :src="logoShop"
        width="100%"
        height="100%"
        style="object-fit: contain; width: 100%; height: 100%"
      /><img
        v-else
        src="@/assets/img/logo_default.png"
        width="100%"
        height="100%"
        style="object-fit: contain; width: 100%; height: 100%"
      />
    </div>
    <div class="w-100 px-5">
      <form v-on:submit.prevent="submitLogin()">
        <div class="form-title">管理画面ログイン</div>
        <b-alert show variant="danger" v-if="alertMessage">{{
          message
        }}</b-alert>
        <p class="sp-only login-title">メールアドレスでログイン</p>
        <div class="d-flex flex-column form-input">
          <label for="email"></label>
          <input
            v-model="email"
            name="email"
            id="email"
            placeholder="メールアドレス"
            style="color: black"
          />
        </div>
        <div class="d-flex flex-column form-input input-group">
          <div class="row ml-0 mr-0 flex-nowrap">
            <input
              v-if="isshowPassword === false"
              v-model="password"
              type="password"
              name="password"
              id="password"
              placeholder="パスワード"
              class="col-11"
            />
            <input
              v-else-if="isshowPassword === true"
              v-model="password"
              name="password"
              id="password"
              placeholder="パスワード"
              class="col-11"
            />
            <span
              v-if="isshowPassword === false"
              class="input-group-text col-auto px-2"
              id="basic-addon2"
              style="
                height: 50px;
                margin: 5px 0px 20px;
                cursor: pointer;
                color: black;
              "
              @click="showPassword"
              ><i class="fas fa-eye" style="width: 17.5px"></i
            ></span>
            <span
              v-else-if="isshowPassword === true"
              class="input-group-text col-auto px-2"
              id="basic-addon2"
              style="
                height: 50px;
                margin: 5px 0px 20px;
                cursor: pointer;
                color: black;
              "
              @click="showPassword"
              ><i class="fas fa-eye-slash"></i
            ></span>
          </div>
        </div>
        <div class="remember-form">
          <input
            v-model="remember_me"
            type="checkbox"
            name="remember"
            id="remember"
          />
          <label class="ml-1" for="remember">ログインしたままにする</label>
        </div>
        <div class="btn-submit">
          <button type="submit">ログイン</button>
        </div>
      </form>
      <div class="d-flex justify-content-around my-3">
        <router-link
          :to="{
            name: $route.params.shopId
              ? 'forgot password admin'
              : 'forgot password admin domain',
            params: { id: $route.params.id },
          }"
          class="color-blue"
          >パスワードをお忘れですか？</router-link
        >
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { Constants } from "../../utils/constants";
import { Api } from "../../utils/http-common";
export default {
  name: "Login",
  data() {
    return {
      email: "",
      password: "",
      remember_me: false,
      alertMessage: false,
      message: "",
      shopId: this.$route.params.shopId,
      shop_id: null,
      logoShop: null,
      dataShop: null,
      isshowPassword: false,
    };
  },
  created() {
    let request = {
      domain: Constants.DOMAIN,
      shop_id: this.$route.params.shopId || null,
    };
    this.getShopById(request);
    // this.shop_id = localStorage.getItem(Constants.SHOP_ID);
    this.getListSettingShopAdmin({
      domain: Constants.DOMAIN,
      shop_id: this.$route.params.shopId,
    });
    const extConstant = this.$route.params.shopId
      ? "_" + Constants.DOMAIN + "_" + this.$route.params.shopId
      : "_" + Constants.DOMAIN;
    if (
      localStorage.getItem(Constants.EMAIL_ADMIN + extConstant) !== null ||
      localStorage.getItem(Constants.EMAIL_ADMIN + extConstant) !== undefined
    ) {
      this.email = localStorage.getItem(Constants.EMAIL_ADMIN + extConstant);
    }
    const token = localStorage.getItem(Constants.TOKEN_ADMIN + extConstant);
    const expires_at = localStorage.getItem(
      Constants.EXPIRES_AT_ADMIN + extConstant
    );
    if (token) {
      const user_type = localStorage.getItem(
        Constants.USER_TYPE_ADMIN + extConstant
      );
      if (
        (user_type === "0" ||
          user_type === "1" ||
          user_type === "2" ||
          user_type === "3") &&
        new Date() <= new Date(expires_at)
      ) {
        if (this.$route.params.shopId) {
          this.$router.push({ name: "Dashboard Admin" });
        } else {
          this.$router.push({ name: "Dashboard Admin domain" });
        }
      }
    }
  },
  computed: {
    ...mapGetters(["pageId", "ListSettingShop", "detailShop"]),
  },
  watch: {
    ListSettingShop() {
      this.dataShop = this.ListSettingShop;
      if (this.dataShop.length > 0) {
        if (this.dataShop[0].logo != null) {
          this.logoShop =
            process.env.VUE_APP_ROOT_BACKEND + "/" + this.dataShop[0].logo;
        }
      }
    },
  },
  methods: {
    ...mapActions({ setPageId: "setPageId" }),
    ...mapActions({ getListSettingShopAdmin: "getListSettingShopAdmin" }),
    ...mapActions({ getShopById: "getShopById" }),
    setAlert() {
      this.alertMessage = true;
    },
    submitLogin() {
      const { params } = this.$route;
      if (!this.email && !this.password) return;
      this.email = this.email ? this.email.trim() : "";
      this.password = this.password ? this.password.trim() : "";
      const formLogin = {
        email: this.email,
        password: this.password,
        shop_id: this.$route.params.shopId,
        domain: Constants.DOMAIN,
      };
      if (this.password !== "" && this.email !== "") {
        Api.userRequestServer
          .post(`/login`, formLogin)
          .then((response) => {
            const { data } = response;
            if (data.success) {
              const { access_token, user, expires_in } = data.data;
              const { user_type, shop_name } = user;
              const expires_at = new Date(
                new Date().setSeconds(
                  new Date().getSeconds() + expires_in - 600
                )
              );
              if (user_type == 0) {
                user.shop_id = this.$route.params.shopId
                  ? this.$route.params.shopId
                  : user.shop_id;
                user.shop_domain = Constants.DOMAIN;
              }
              if (
                user_type === 0 ||
                user_type === 1 ||
                user_type === 2 ||
                user_type === 3
              ) {
                const extConstant = this.$route.params.shopId
                  ? "_" + Constants.DOMAIN + "_" + this.$route.params.shopId
                  : "_" + Constants.DOMAIN;
                localStorage.setItem(Constants.TOKEN_ADMIN, access_token);
                localStorage.setItem(Constants.USER_TYPE_ADMIN, user_type);
                localStorage.setItem(Constants.SHOP_NAME, shop_name);
                localStorage.setItem(
                  Constants.ADMIN_USER_INFO,
                  JSON.stringify(user)
                );
                localStorage.setItem(Constants.EXPIRES_AT_ADMIN, expires_at);
                localStorage.setItem(Constants.EMAIL_ADMIN, user.email);
                localStorage.setItem(
                  Constants.TOKEN_ADMIN + extConstant,
                  access_token
                );
                localStorage.setItem(
                  Constants.USER_TYPE_ADMIN + extConstant,
                  user_type
                );
                localStorage.setItem(
                  Constants.SHOP_NAME + extConstant,
                  shop_name
                );
                localStorage.setItem(
                  Constants.ADMIN_USER_INFO + extConstant,
                  JSON.stringify(user)
                );
                localStorage.setItem(
                  Constants.EXPIRES_AT_ADMIN + extConstant,
                  expires_at
                );
                localStorage.setItem(
                  Constants.IS_REMEMBER_ADMIN,
                  this.remember_me
                );
                localStorage.setItem(
                  Constants.EMAIL_ADMIN + extConstant,
                  user.email
                );
                if (params.shopId) {
                  this.$router.push({
                    name: "Dashboard Admin",
                    shopId: this.shop_id,
                  });
                } else {
                  this.$router.push({
                    name: "Dashboard Admin domain",
                  });
                }
              } else {
                this.message =
                  "メールアドレスもしくはパスワードが間違っています";
                this.setAlert();
                setTimeout(() => (this.alertMessage = false), 5000);
              }
            } else {
              this.message = data.message;
              this.setAlert();
              setTimeout(() => (this.alertMessage = false), 5000);
            }
          })
          .catch((error) => {
            this.message = error.message;
            this.setAlert();
            setTimeout(() => (this.alertMessage = false), 5000);
          });
      } else {
        this.message = "メールアドレスまたはパスワードを入力してください";
        this.setAlert();
        setTimeout(() => (this.alertMessage = false), 5000);
      }
    },
    showPassword() {
      this.isshowPassword = !this.isshowPassword;
    },
  },
};
</script>
<style>
.formLogin {
  margin: auto;
  background-color: rgba(255, 255, 255, 0.8) !important;
  padding: 20px;
  border-radius: 10px;
}
/* Fix bug login don't show background */
@media screen and (max-width: 767px) {
  .formLogin {
    width: 80% !important;
  }
}
@media screen and (min-width: 768px) {
  .formLogin {
    width: 540px !important;
  }
}
@media screen and (min-width: 540px) {
  .formLogin {
    width: 520px !important;
  }
}
@media screen and (max-width: 768px) and (orientation: landscape) {
  .formLogin {
    width: 540px !important;
  }
}

.img-logo-header {
  width: 286px;
  height: 79px;
}
</style>
